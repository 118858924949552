<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <br />
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
        <v-row class="bradcrumb">
            <v-col cols="12" md="12" sm="12">
                <router-link style="float:right;" :to="{ name: 'import-bookitem-new' }">
                    <v-btn class="mb-2 button">Import Book Item</v-btn>&nbsp;&nbsp;
                </router-link>
            </v-col>
        </v-row>
        <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
                <v-card width="400">
                    <center class="pa-5">
                        Importing Book data.<br />Please Wait It will Take Time..
                    </center>
                    <div class="ma-6">
                        <v-progress-linear v-model="percent" color="amber" height="25">
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </div>
                </v-card>
            </v-row>
        </v-dialog>
        <v-card class="card">
            <v-card-title class="heading justify-center">Import Books</v-card-title>
            <v-card-text>
                <div class="add-section">
                    <v-row class="justify-content-between set-btn">
                        <div><a
                                href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_book_xlsx.xlsx">
                                <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export XLSX
                                    Template</v-btn></a>
                        </div>
                    </v-row>
                    <p class="file-warn">NOTE: *File should be in XLSX format.</p>
                    <v-row class="justify-content-center">
                        <v-col lg="5" sm="12" md="5" class="import-box">
                            <label class="label">IMPORT NEW BOOKS XLSX</label>
                            <v-file-input dense v-model="file" :rules="[v => !!v || 'File required']"
                                :hint="file != null ? '' : 'Please Select File'" persistent-hint color="rgb(1, 127, 152)"
                                label="Choose File" accept=".xlsx" prepend-icon="mdi-paperclip"
                                :error-messages="!fileStatus ? 'File Required' : ''" outlined show-size></v-file-input>
                            <v-btn :loading="output_load" @click="importCsv" class="button">
                                Import Books <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col lg="5" sm="12" md="5" class="import-box">
                            <label class="label">UPDATE EXISTING BOOKS XLSX</label>
                            <v-file-input dense v-model="fileupdate" :rules="[v => !!v || 'File required']"
                                :hint="fileupdate != null ? '' : 'Please Select File'" persistent-hint
                                color="rgb(1, 127, 152)" label="Choose File" accept=".xlsx" prepend-icon="mdi-paperclip"
                                :error-messages="!fileStatusupdate ? 'File Required' : ''" outlined show-size>
                            </v-file-input>
                            <v-btn @click="importCsvUpdate" :loading="output_load_update" class="button">
                                Update Books
                                <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <template>
                    <h5 v-if="failList.length > 0" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    <v-btn v-if="failList.length > 0" id="export" @click="exportexcel()" color="success"
                        style="float:right;" dark>
                        <v-icon dark>mdi-file-excel</v-icon>
                    </v-btn>
                    <v-simple-table v-if="failList.length > 0" id="exceltable">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Excel row no</th>
                                    <th>Error</th>
                                    <th>ISBN</th>
                                    <th>Series</th>
                                    <th>Title - Subtitle</th>
                                    <th>author</th>
                                    <th>Edition</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name" style="color:red;">
                                    <th>{{ item.rowno }} </th>
                                    <th>{{ item.error }} </th>
                                    <th>{{ item.row.isbn }} </th>
                                    <th>{{ item.row.series }} </th>
                                    <th>{{ item.row.title_subtitle }} </th>
                                    <th>{{ item.row.author }} </th>
                                    <th>{{ item.row.edition }} </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <template>
                    <h5 v-if="successList.length > 0" class="mt-4" style="text-align: center; color: green;">Success Record
                    </h5>
                    <v-btn v-if="successList.length > 0" id="export" @click="exportexcel1()" color="success"
                        style="float:right;" dark>
                        <v-icon dark>mdi-file-excel</v-icon>
                    </v-btn>
                    <v-simple-table v-if="successList.length > 0" id="exceltable1">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Excel row no</th>
                                    <th>ISBN</th>
                                    <th>Series</th>
                                    <th>Title - Subtitle</th>
                                    <th>author</th>
                                    <th>Edition</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in successList" :key="item.name" style="color:green;">
                                    <th>{{ item.rowno }} </th>
                                    <th>{{ item.row.isbn }} </th>
                                    <th>{{ item.row.series }} </th>
                                    <th>{{ item.row.title_subtitle }} </th>
                                    <th>{{ item.row.author }} </th>
                                    <th>{{ item.row.edition }} </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import { table2excel } from "../jquery.table2excel";
import "@/assets/css/color.css";
export default {
    data: () => ({

        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",

        file: null,
        fileupdate: null,
        isLoading: false,
        failList: [],
        successList: [],
        output_load: false,
        output_load_update: false,
        fileStatus: true,
        fileStatusupdate: true,
        count: -1,
        countupdate: -1,
        percent: 0,
        percentupdate: 0,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Import Data',
                disabled: false,
                href: '/imoprt-file',
            },
            {
                text: 'Import Books',
                disabled: true,
                href: '',
            },
        ]
    }),

    methods: {
        exportexcel() {

            $("#exceltable").table2excel({
                name: "Failed Record Book",
                exclude_img: false,
                filename: "Failed_Book", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },
        exportexcel1() {

            $("#exceltable1").table2excel({
                name: "Success Record Book",
                exclude_img: false,
                filename: "Success_Book", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },
        fileuploading() {
            let formData = new FormData();
            this.fileStatus = true;
            formData.append("file", this.file);
            formData.append("count", this.count);
            axios
                .post("ImportData/importBookNew", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.status == "200") {
                        if (this.count != -1) {
                            for (var i = 0; i < res.data.successList.length; i++) {
                                this.successList.push(res.data.successList[i]);
                            }

                            for (var i = 0; i < res.data.failList.length; i++) {
                                this.failList.push(res.data.failList[i]);
                            }
                        }

                        this.count = res.data.count;
                        this.totalbooksize = res.data.totalbooksize;
                        this.percent = (this.count / this.totalbooksize) * 100;
                        if (res.data.isdone) {
                            this.count = -1;
                            this.isLoading = false;
                            this.showSnackbar("#4caf50", "Books added successfully...");
                        } else {
                            this.fileuploading();
                        }
                    }
                })
        },

        importCsv() {
            this.failList = [];
            this.successList = [];
            if (this.file !== null) {
                this.isLoading = true;
                if (!(this.file.name.toLowerCase().includes(".xlsx") || this.file.name.toLowerCase().includes(".XLSX"))) {
                    this.showSnackbar("red", "File must be in xlsx format Only.!");
                } else {
                    this.fileuploading();
                }
            } else {
                this.fileStatus = false;
                this.showSnackbar("#b71c1c", "Please select file");
            }
        },



        fileuploadingUpdate() {
            let formData = new FormData();
            this.fileStatusupdate = true;
            formData.append("fileupdate", this.fileupdate);
            formData.append("countupdate", this.countupdate);
            axios
                .post("ImportData/importExistingBooks", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.status == "200") {
                        if (this.countupdate != -1) {
                            for (var i = 0; i < res.data.successList.length; i++) {
                                this.successList.push(res.data.successList[i]);
                            }
                            for (var i = 0; i < res.data.failList.length; i++) {
                                this.failList.push(res.data.failList[i]);
                            }
                        }
                        this.countupdate = res.data.count;
                        this.totalbillsizeupdate = res.data.totalbillsize;
                        this.percentupdate = (this.count / this.totalbillsizeupdate) * 100;
                        if (res.data.isdone) {
                            this.countupdate = -1;
                            this.isLoading = false;
                            this.showSnackbar("#4caf50", "Books Updated successfully...");
                        } else {
                            this.fileuploadingUpdate();
                        }
                    }
                })

        },

        importCsvUpdate() {
            if (confirm("Are You Sure You Want To Update Book Details?")) {
                this.failList = [];
                this.successList = [];
                if (this.fileupdate !== null) {
                    this.isLoading = true;
                    if (!(this.fileupdate.name.toLowerCase().includes(".xlsx") || this.fileupdate.name.toLowerCase().includes(".XLSX"))) {
                        this.showSnackbar("red", "File must be in xlsx format Only.!");
                    } else {
                        this.fileuploadingUpdate();
                    }
                } else {
                    this.fileStatusupdate = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            }
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.add-section {
    display: block;
}

.import-box {
    margin: 1rem 1rem 2rem 1rem;
    border: 2px solid;
    padding: 1rem 2rem 1rem 2rem;
    text-align: center;
    background: #ffffffb5;
}

.label {
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.button {
    background: white !important;
}

.set-btn {
    margin: 0rem 1rem 0rem 1rem;
    padding-top: 1rem;
}

.button:hover {
    color: white;
    background: black !important;
}

.file-warn {
    font-style: italic;
    color: red;
    text-align: center;
    margin: 0;
}
</style>
